<template>
  <div :class="prefixCls">
<!--    <img src="../../../assets/image/logo1.png" />-->
    <img src="@/assets/image/favicon.png" alt="">
    <span class="title">金鳌船艇</span>
<!--    <div class="diving-line" />-->
<!--    <span class="label">Since 2023</span>-->
  </div>
</template>

<script>
export default {
  data () {
    return {
      prefixCls: this.$prefix + '-layout-header-logo'
    }
  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
  $prefixCls: $namespace + -layout-header-logo;
  .#{$prefixCls}{
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    img{
      height: 35px;
    }
    .title {
      margin-left: 20px;
      color: rgb(46, 107, 181) !important;
      font-size: 26px;
      font-weight: bold;
      letter-spacing: 4px;
    }
    .diving-line {
      width: 1px;
      height: 20px;
      background-color: rgb(191, 191, 191);
      margin: 0 10px;
    }
    .label {
      font-size: 18px;
      color: rgb(191, 191, 191);
    }
  }
</style>
