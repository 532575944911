<template>
  <div :class="prefixCls" id="scrollContainer">
    <!-- <Header></Header> -->
    <transition name="custom"
    mode="out-in"
    appear>
<!--      <keep-alive v-if="keepAlive">-->
<!--        <router-view></router-view>-->
<!--      </keep-alive>-->
      <router-view></router-view>
    </transition>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from './footer.vue'

export default {
  data () {
    return {
      prefixCls: this.$prefix + '-layout',
      keepAlive: true
    }
  },
  watch: {
    $route: function (route) {
      if (route.meta.keepAlive) {
        this.keepAlive = route.meta.keepAlive
      } else {
        this.keepAlive = true
      }
    }
  },
  components: {
    Footer
  }
}
</script>

<style lang="scss" scoped>
  $prefixCls: $namespace + -layout;
  .#{$prefixCls}{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100% - 80px);
    overflow-y: auto;
    overflow-x: hidden;
  }
</style>
